import { useState } from "react";

import { CreateCompany } from "@elton/types";
import { Sheet, SheetContent } from "@elton/ui/core";
import { formatCentsToDollars } from "@elton/utils";

import {
  CompanyPartialForm,
  FormRenderState,
} from "@/components/forms/company-form/partial/company-partial-form";
import {
  TopSectionColumn,
  TopSectionDescription,
  TopSectionHeading,
  TopSectionRow,
} from "@/components/sections/top-section";

import { DetailsCard } from "./components/details-card/details-card";
import { useMonthlyCostConverter } from "./hooks/use-monthly-cost-converter";

import { useUpdateCompanyMutation } from "@/api/mutations/company/update-company-mutation";
import { useRole } from "@/context/role-context/use-role";
import { Route as CompanySettingsRoute } from "@/routes/_authenticated/companies/$companyId/_dashboard/settings";
import { T, useT } from "@transifex/react";

export const CompanyDetailsPage: React.FC = () => {
  const t = useT();
  const { isAdmin } = useRole();

  const loaderData = CompanySettingsRoute.useLoaderData();
  const updateCompany = useUpdateCompanyMutation();

  const [formOpen, setFormOpen] = useState(false);
  const [formState, setFormState] = useState<FormRenderState | null>(null);
  const monthyCostValue = useMonthlyCostConverter({
    monthlyCost: loaderData.companyPlan?.monthlyCost,
    currency: loaderData.companyPlan?.monthlyCostCurrency,
  });

  const handleChangeFormRenderState = (state: FormRenderState) => () => {
    setFormState(state);
    setFormOpen(true);
  };

  const handleUpdateCompany = async (data: CreateCompany) => {
    await updateCompany.mutateAsync({
      companyId: loaderData.companyId,
      data,
    });
    setFormOpen(false);
  };

  return (
    <>
      <Sheet open={formOpen} onOpenChange={setFormOpen}>
        <SheetContent>
          <CompanyPartialForm
            loading={updateCompany.isPending}
            state={formState}
            data={{
              ...loaderData.company,
              paymentPlanId: loaderData.companyPlan?.basePlanId,
              userLimit: loaderData.companyPlan?.userLimit,
              monthlyCost: loaderData.companyPlan?.monthlyCost
                ? formatCentsToDollars(loaderData.companyPlan?.monthlyCost)
                : 0,
            }}
            paymentPlans={loaderData.paymentPlans.data}
            onUpdate={handleUpdateCompany}
          />
        </SheetContent>
      </Sheet>

      <TopSectionRow className="mb-16 ">
        <TopSectionColumn className="w-full md:w-3/4">
          <TopSectionHeading>
            <T _str="Company details" />
          </TopSectionHeading>
          <TopSectionDescription>
            <T _str="Here you can access company details and make necessary updates to ensure all information remains current and accurate." />
          </TopSectionDescription>
        </TopSectionColumn>
      </TopSectionRow>

      <div className="flex flex-wrap">
        <div className="w-full xl:w-3/5 xl:pr-3 mb-8">
          <DetailsCard
            editable={isAdmin}
            title={t("Company Information")}
            description={t("Key details about your company")}
            data={[
              {
                key: t("Name"),
                value: loaderData.company.name,
              },
              {
                key: t("Org. num"),
                value: loaderData.company.organizationNumber,
              },
              {
                key: t("Contact name"),
                value: loaderData.company.contactName,
              },
              {
                key: t("Contact email"),
                value: loaderData.company.contactEmail ?? "-",
              },
              {
                key: t("Contact phone"),
                value: loaderData.company.contactPhone,
              },
            ]}
            onClickEdit={handleChangeFormRenderState("KEY")}
            className="w-full h-full"
          />
        </div>
        <div className="w-full xl:w-2/5 xl:pl-3 mb-8">
          <DetailsCard
            editable={isAdmin}
            title={t("Invoicing")}
            description={t("Relevant information for invoicing")}
            data={[
              {
                key: t("Invoicing email"),
                value: loaderData.company.invoiceEmail,
              },
              {
                key: t("Payment time"),
                value: loaderData.company.invoicePaymentTime?.toString() ?? "-",
              },
              {
                key: t("Invoice ID (External)"),
                value: loaderData.company.externalInvoiceId ?? "-",
              },
            ]}
            onClickEdit={handleChangeFormRenderState("INVOICING")}
            className="w-full h-full"
          />
        </div>
        <div className="w-full xl:w-3/5 xl:pr-3 mb-8">
          <DetailsCard
            editable={isAdmin}
            title={t("Address")}
            description={t("Registered company address used for invoicing.")}
            data={[
              {
                key: t("Address"),
                value: loaderData.company.address,
              },
              {
                key: t("Postal code"),
                value: loaderData.company.postalCode,
              },
              {
                key: t("City"),
                value: loaderData.company.city,
              },
              {
                key: t("Country"),
                value: loaderData.company.country,
              },
            ]}
            onClickEdit={handleChangeFormRenderState("ADDRESS")}
            className="w-full h-full"
          />
        </div>
        <div className="w-full xl:w-2/5 xl:pl-3 mb-8">
          <DetailsCard
            editable={isAdmin}
            title={t("Payment & Plan")}
            description={t("Customized payment plan and terms")}
            data={[
              {
                key: t("Payment plan"),
                value: loaderData.companyPlan?.name ?? "-",
              },
              {
                key: t("Monthly cost"),
                value: `${monthyCostValue}`,
              },
              {
                key: t("Discount"),
                value: `${loaderData.company.discountPercentage ?? "0"}%`,
              },
              {
                key: t("User limit"),
                value: `${loaderData.companyPlan?.userLimit}`,
              },
            ]}
            onClickEdit={handleChangeFormRenderState("PAYMENT")}
            className="w-full h-full"
          />
        </div>
        {isAdmin && (
          <div className="w-full xl:w-3/5 xl:pr-3">
            <DetailsCard
              editable={isAdmin}
              title={t("Internal (Elton)")}
              description={t("Information only visible to Elton staff")}
              data={[
                {
                  key: t("Note"),
                  value: loaderData.company.internalNote ?? "-",
                },
              ]}
              onClickEdit={handleChangeFormRenderState("NOTE")}
              className="w-full"
            />
          </div>
        )}
      </div>
    </>
  );
};
