import { z } from "zod";

export const basePaymentPlan = z.object({
  id: z.string(),
  name: z.string(),
  userLimit: z.number(),
  monthlyCost: z.number(),
});

export type BasePaymentPlan = z.infer<typeof basePaymentPlan>;

export const paymentPlan = z.object({
  basePlanId: z.string(),
  name: z.string(),
  userLimit: z.number(),
  monthlyCost: z.number(),
  monthlyCostCurrency: z.string(),
});

export type PaymentPlan = z.infer<typeof paymentPlan>;
