import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { useToast } from "@elton/ui/core";

import { createSessionsMasterExportOptions } from "@/api/options/export-options";
import { useT } from "@transifex/react";

export const useCreateSessionsMasterExportMutation = () => {
  const t = useT();
  const { toast } = useToast();

  return useMutation({
    ...createSessionsMasterExportOptions(),
    onSuccess: () => {
      toast({
        variant: "success",
        description: t("Export was created successfully!"),
      });
    },
    onError: (err: AxiosError<{ message?: string }>) => {
      toast({
        variant: "error",
        description: `${t("Export could not be created.")} ${err?.response?.data?.message ?? ""}`,
      });
    },
  });
};
