import { CellContext, ColumnDef } from "@tanstack/react-table";

import { Employee } from "@elton/types";

import { UserDropdownActions } from "../actions/user-actions";
import {
  AlignRightColumn,
  MediumColumn,
  SortableColumn,
} from "../components/table-columns";

import { useT } from "@transifex/react";

type UserColumnDef = ColumnDef<Employee>[];
type UserCellContext = CellContext<Employee, unknown>;

interface IGetUserColumnProps {
  onEditUser: (user: Employee) => void;
  onDeleteUser: (user: Employee) => void;
  onCopyUserId: (userId: string) => void;
}

export const useEmployeeColumns = ({
  onEditUser,
  onDeleteUser,
  onCopyUserId,
}: IGetUserColumnProps): UserColumnDef => {
  const t = useT();

  return [
    {
      accessorKey: "id",
      header: SortableColumn("ID"),
      meta: {
        header: t("ID"),
      },
    },
    {
      accessorKey: "name",
      header: SortableColumn(t("Name")),
      cell: ({ row }: UserCellContext) => (
        <MediumColumn title={row.original.name} />
      ),
      size: 10,
      meta: {
        header: t("Name"),
      },
    },
    {
      accessorKey: "phoneNumber",
      header: SortableColumn(t("Phone")),
      size: 10,
      meta: {
        header: t("Phone"),
      },
    },
    {
      accessorKey: "registrationNumber",
      header: SortableColumn(t("Reg. num")),
      size: 10,
      meta: {
        header: t("Reg. num"),
      },
    },
    {
      accessorKey: "defaultNote",
      header: SortableColumn(t("Default Note")),
      size: 5,
      meta: {
        header: t("Default Note"),
      },
    },
    {
      accessorKey: "department.name",
      header: t("Department"),
      size: 10,
      meta: {
        header: t("Department"),
      },
    },
    {
      accessorKey: "reference",
      header: SortableColumn(t("External Reference")),
      size: 5,
      meta: {
        header: t("External Reference"),
      },
    },
    {
      id: "actions",
      enableHiding: false,
      header: () => <AlignRightColumn title={t("Actions")} />,
      cell: ({ row }: UserCellContext) => {
        return (
          <UserDropdownActions
            className="flex justify-end text-right"
            data={row.original}
            onEditUser={onEditUser}
            onDeleteUser={onDeleteUser}
            onCopyUserId={onCopyUserId}
          />
        );
      },
    },
  ];
};
