import { z } from "zod";
import { validatePhoneNumber } from "../../validators/validate-phone-number";

export const employeeSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(3, { message: "Name is required" }),
  phoneNumber: z.string(),
  registrationNumber: z.string().optional(),
  defaultNote: z.string().optional(),
  reference: z.string().optional(),
  department: z
    .object({
      id: z.string().optional(),
      name: z.string().optional(),
    })
    .optional(),
});

export const createEmployeeSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(3, { message: "Name is required" }),
  phoneNumber: z.string().transform(validatePhoneNumber),
  defaultNote: z.string().optional(),
  registrationNumber: z.string().optional(),
  reference: z.string().optional(),
  departmentId: z.string().min(1, { message: "A department is required" }),
});

export type Employee = z.infer<typeof employeeSchema>;
export type CreateEmployee = z.infer<typeof createEmployeeSchema>;
