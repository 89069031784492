import { PaymentPlan } from "@elton/types";

import PostHogAdapter from "@/adapters/posthog-adapter.ts";
import { getServicesClient } from "@/api/clients/api-client";

export const getCompanyPaymentPlanOverride = async (
  companyId: string
): Promise<PaymentPlan | null> => {
  try {
    const res = await getServicesClient().get<PaymentPlan>(
      `/companies/${companyId}/plan`
    );

    PostHogAdapter.logCompanyPlan(res.data)

    return res.data;
  } catch (err) {
    // TODO: Handle 404 errors, all else could be thrown ??
    console.warn("Failed to get company payment plan override", err);
    return null;
  }
};


