import { useT } from "@transifex/react";

export const useUpgradeEmailLink = () => {
  const t = useT();
  const subject = t("Upgrade Plan");
  const body = t(
    "Hello Elton team, I would like to upgrade my plan. Please contact me at your earliest convenience.",
  );

  return `mailto:bedrift@elton.app?subject=${subject}&body=${body}`;
};
