import { useCallback } from "react";
import { useNavigate } from "@tanstack/react-router";
import debounce from "debounce";

export const useDebouncedSearchNavigation = (
  initialPath: string,
  searchParams: any
) => {
  const navigate = useNavigate({ from: initialPath });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedNavigate = useCallback(
    debounce((filterValue: string) => {
      const nextSearch = {
        ...searchParams,
        filter: filterValue,
      };

      if (filterValue !== searchParams.filter) {
        nextSearch.offset = 0;
        nextSearch.limit = 10;
        nextSearch.filter = filterValue;
      }

      navigate({
        search: nextSearch,
        //params: {},
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // search: (prevSearchParams: any) => {
        //   /**
        //    * When the filter value changes, we need to reset the offset and limit
        //    */
        //   if (prevSearchParams.filter !== filterValue) {
        //     return {
        //       ...prevSearchParams,
        //       offset: 0,
        //       limit: 10,
        //       filter: filterValue,
        //     };
        //   }
        //   return {
        //     ...prevSearchParams,
        //     filter: filterValue,
        //   };
        // },
      });
    }, 500),
    [navigate, searchParams]
  );

  const removeOpenParameter = useCallback(() => {
    navigate({
      search: {
        ...searchParams,
        open: undefined,
      },
      // params: {},
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // search: (prevSearchParams: any) => ({
      //   ...prevSearchParams,
      //   open: undefined,
      // }),
    });
  }, [navigate, searchParams]);

  return {
    updateSearchFilter: debouncedNavigate,
    removeOpenParameter,
  };
};
