import { useEffect } from "react";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";

import { MasterExportInternal } from "./master-export-types";

export const useMasterExportInternalForm = ({
  data,
  onSubmit,
}: {
  data: MasterExportInternal;
  onSubmit: (value: MasterExportInternal) => void;
}) => {
  const form = useForm({
    defaultValues: data,
    validatorAdapter: zodValidator(),
    onSubmit: (values) => onSubmit(values.value),
  });

  // Only update `date` field when it actually changes
  useEffect(() => {
    form.state.values.date;
    if (form.state.values.date !== data.date) {
      form.setFieldValue("date", data.date);
    }
  }, [data.date, form]);

  return form;
};
