import { flexRender, Header } from "@tanstack/react-table";

import { TableHead, TableRow } from "@elton/ui/core";

interface ITableHeaderProps<TData> {
  headers: Header<TData, unknown>[];
}

export const TableHeaderRow: React.FC<ITableHeaderProps<any>> = ({
  headers,
}) => {
  return (
    <TableRow>
      {headers.map((header) => {
        const size = header.getSize();

        return (
          <TableHead
            key={header.id}
            style={{
              width: size ? `${size}%` : undefined,
            }}
          >
            {header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext())}
          </TableHead>
        );
      })}
    </TableRow>
  );
};
