import { useCallback, useEffect, useRef } from "react";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { endOfDay, startOfDay } from "date-fns";
import debounce from "debounce";

interface DateRange {
  dateFrom?: Date;
  dateTo?: Date;
}

interface SearchParams {
  filter?: string;
  offset?: number;
  limit?: number;
  dateFrom?: string;
  dateTo?: string;
}

const DEFAULT_LIMIT = 10;
const FILTER_DEBOUNCE_MS = 500;
const DATE_DEBOUNCE_MS = 0;

export const useSessionSearchParamsUpdater = (initialPath: string) => {
  const previousSearch = useSearch({
    from: "/_authenticated/companies/$companyId/_dashboard/sessions",
  });

  const navigate = useNavigate({ from: initialPath });

  // Refs to store debounced functions
  const debouncedFilterRef = useRef<ReturnType<typeof debounce>>();
  const debouncedDateRangeRef = useRef<ReturnType<typeof debounce>>();

  const updateSearchParams = useCallback(
    (updates: Partial<SearchParams>) => {
      const nextSearch = {
        ...previousSearch,
        ...updates,
      };

      navigate({
        search: nextSearch as any,
        replace: true, // Add replace to prevent building up history
      });
    },
    [navigate, previousSearch],
  );

  // Create debounced functions once
  useEffect(() => {
    debouncedFilterRef.current = debounce((filterValue: string) => {
      const updates: Partial<SearchParams> = {
        filter: filterValue,
        offset: 0, // Always reset pagination on filter change
        limit: DEFAULT_LIMIT,
      };

      updateSearchParams(updates);
    }, FILTER_DEBOUNCE_MS);

    debouncedDateRangeRef.current = debounce((dateRange: DateRange) => {
      const updates: Partial<SearchParams> = {
        dateFrom: dateRange.dateFrom
          ? startOfDay(new Date(dateRange.dateFrom)).toISOString()
          : undefined,
        dateTo: dateRange.dateTo
          ? endOfDay(new Date(dateRange.dateTo)).toISOString()
          : undefined,
        offset: 0, // Always reset pagination on date change
        limit: DEFAULT_LIMIT,
      };

      updateSearchParams(updates);
    }, DATE_DEBOUNCE_MS);

    return () => {
      debouncedFilterRef.current?.clear();
      debouncedDateRangeRef.current?.clear();
    };
  }, [updateSearchParams]); // Remove previousSearch.filter dependency

  const handleUpdateFilter = useCallback(
    (filterValue: string) => {
      debouncedFilterRef.current?.(filterValue);
    },
    [], // Remove previousSearch.filter dependency
  );

  const handleUpdateDateRange = useCallback(
    (dateRange: DateRange) => {
      debouncedDateRangeRef.current?.(dateRange);
    },
    [], // Remove previousSearch dependencies
  );

  return {
    updateSearchFilter: handleUpdateFilter,
    updateDateRange: handleUpdateDateRange,
  };
};
