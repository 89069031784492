import { kindeClient } from "@/api/clients/kinde-client";
import * as Sentry from "@sentry/react";

let hasAuthenticatedOnce = false;

const init = () => {
  if (!import.meta.env.PROD) {
    console.warn("Sentry is disabled in development mode");
    return;
  }

  Sentry.init({
    dsn: "https://9d53869c9ac3d2baaea4d531e7ef26aa@o4504599516217344.ingest.us.sentry.io/4507311430762496",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

const setUser = async () => {
  if(hasAuthenticatedOnce) {
    return;
  }

  const user = await kindeClient.getUser();

  if (!Sentry.isInitialized()) {
    if(import.meta.env.PROD) console.warn("Sentry is not initialized. Could not set user.");
    return;
  }

  if (!user || !user.id) {
    console.warn(
      "Sentry is not able to set user. Could not retrieve an authenticated user."
    );
    return;
  }

  hasAuthenticatedOnce = true;
  Sentry.setUser({ id: user.id });
};

const captureException = (err: Error) => {
  if (!Sentry.isInitialized()) {
    console.warn("Sentry is not initialized. Could not capture exception.");
    console.error(err);
    return;
  }

  Sentry.captureException(err);
};

const SentryAdapter = {
  init,
  setUser,
  captureException,
};

export default SentryAdapter;
