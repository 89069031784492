import { createFileRoute, redirect } from "@tanstack/react-router";

import { EltonRoleStoreKey } from "@/context/role-context/role-context";

const requireSuperadmin = () => {
  const role = localStorage.getItem(EltonRoleStoreKey);

  if (role !== "superadmin") {
    throw redirect({ to: "/" });
  }
};

export const Route = createFileRoute("/_authenticated/_restricted")({
  beforeLoad: requireSuperadmin,
});
