import { CreateMasterExport } from "@elton/types";
import { saveFile } from "@elton/utils";

import { getServicesClient } from "@/api/clients/api-client";

export type MutationFnParams = CreateMasterExport;

export const createSessionsMasterExport = async (body: MutationFnParams) => {
  const res = await getServicesClient().post(
    "/invoice/export",
    {
      format: body.format,
      monthNumber: body.monthNumber,
      year: body.year,
    },
    {
      responseType: "blob",
    },
  );

  const filename = `master_exports_${body.year}_${body.monthNumber}.${body.format}`;

  saveFile(filename, res.data);

  return res.data;
};
