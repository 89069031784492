import { CreateCompany } from "@elton/types";
import { formatDollarsToCents } from "@elton/utils";

import { getServicesClient } from "@/api/clients/api-client";

export const updateCompany = async (companyId: string, data: CreateCompany) => {
  const res = await getServicesClient().put(`/companies/${companyId}`, {
    name: data.name,
    organizationNumber: data.organizationNumber,
    address: data.address,
    postalCode: data.postalCode,
    city: data.city,
    country: data.country,
    contactName: data.contactName,
    contactPhone: data.contactPhone,
    contactEmail: data.contactEmail,
    invoiceEmail: data.invoiceEmail,
    discountPercentage: data.discountPercentage,
    invoicePaymentTime: data.invoicePaymentTime,
    internalNote: data.internalNote,
    externalInvoiceId: data.externalInvoiceId,
  });

  if (data.paymentPlanId) {
    await getServicesClient().put(`/companies/${companyId}/plan`, {
      basePlanId: data.paymentPlanId,
      userLimit: data.userLimit,
      monthlyCost: data.monthlyCost
        ? formatDollarsToCents(data.monthlyCost)
        : undefined,
    });
  }

  return res.data;
};
