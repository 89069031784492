import { z } from "zod";
import { invoiceCountryCodes } from "./invoicing";
import { validatePhoneNumber } from "../../validators/validate-phone-number";

export const companySchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: "Name is required" }),
  organizationNumber: z
    .string()
    .min(9, { message: "Organization number must be 9 digits long" }),
  address: z.string().min(1, { message: "Street address is required" }),
  postalCode: z
    .string()
    .min(4, { message: "Postal code must be 4 digits long" }),
  city: z.string().min(1, { message: "City is required" }),
  country: z.string().min(1, { message: "Country is required" }),
  contactName: z.string().min(1, { message: "Contact name is required" }),
  contactPhone: z.string().transform(validatePhoneNumber),
  contactEmail: z
    .string()
    .email({ message: "Must be a valid email adress" })
    .optional(),
  invoiceEmail: z.string().email({ message: "Must be a valid email adress" }),
  customerNumber: z.string().min(1, { message: "Customer number is required" }),
  discountPercentage: z.number().optional(),
  invoicingReferences: z.object({
    countryCode: invoiceCountryCodes,
    reference: z.string().optional(),
  }),
  invoicePaymentTime: z.number().optional(),
  internalNote: z.string().optional(),
  externalInvoiceId: z.string().optional(),
});

export const createCompanySchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: "Name is required" }),
  organizationNumber: z
    .string()
    .min(9, { message: "Organization number must be 9 digits long" }),
  address: z.string().optional(),
  postalCode: z.string().optional(),
  city: z.string().optional(),
  country: z.string().optional(),
  contactName: z.string().optional(),
  contactPhone: z.string().transform(validatePhoneNumber).optional(),
  contactEmail: z.string().optional(),
  externalInvoiceId: z.string().optional(),
  invoiceEmail: z.string().optional(),
  paymentPlanId: z.string().optional(),
  discountPercentage: z.number().optional(),
  invoicePaymentTime: z.number().optional(),
  monthlyCost: z.number().optional(),
  internalNote: z.string().optional(),
  userLimit: z.number().optional(),
});

export type Company = z.infer<typeof companySchema>;
export type CreateCompany = z.infer<typeof createCompanySchema>;

export type ListCompany = Pick<
  Company,
  "id" | "name" | "organizationNumber" | "customerNumber"
>;
