import { CreateMasterExport } from "@elton/types";

import { MasterExportForm } from "@/components/forms/master-export-form/master-export-form";
import {
  TopSectionColumn,
  TopSectionDescription,
  TopSectionHeading,
  TopSectionRow,
} from "@/components/sections/top-section";

import { BlankLayout } from "../_layouts/blank-layout/blank-layout";

import { useCreateSessionsMasterExportMutation } from "@/api/mutations/exports/create-sessions-master-export-mutation";
import { T } from "@transifex/react";

export const ExportsPage: React.FC = () => {
  const createSessionsMasterExport = useCreateSessionsMasterExportMutation();

  const handleCreateMasterExport = async (data: CreateMasterExport) => {
    await createSessionsMasterExport.mutateAsync(data);
  };

  return (
    <BlankLayout>
      <TopSectionRow className="mb-8">
        <TopSectionColumn>
          <TopSectionHeading>
            <T _str="Invoice Export" />
          </TopSectionHeading>
          <TopSectionDescription>
            <T _str="Create master export for invoicing" />
          </TopSectionDescription>
        </TopSectionColumn>
      </TopSectionRow>
      <MasterExportForm
        loading={createSessionsMasterExport.isPending}
        onCreateExport={handleCreateMasterExport}
        onChange={() => {}}
      />
    </BlankLayout>
  );
};
