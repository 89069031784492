import { useMemo } from "react";
import { Column } from "@tanstack/react-table";

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@elton/ui/core";
import { Button } from "@elton/ui/core";
import { ChevronDown, Sliders } from "@elton/ui/icons";

import PostHogAdapter from "@/adapters/posthog-adapter.ts";
import { T } from "@transifex/react";

interface IProps {
  columns: Column<any, unknown>[];
}

export const TableColumnsDropdown: React.FC<IProps> = ({ columns }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="ml-auto gap-1">
          <span className="sr-only">
            <T _str="Show/hide columns" />
          </span>
          <Sliders className="w-4 h-4" />
          <ChevronDown className="w-3 h-auto stroke-primary" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>
          <T _str="Select columns" />
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        {columns.map((column, i) => (
          <CheckboxItem key={i} column={column} />
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const CheckboxItem: React.FC<{
  column: any;
}> = ({ column }) => {
  const title = useMemo(() => {
    /**
     * If the column has a meta header, use it.
     */
    if (column.columnDef.meta?.header) {
      return column.columnDef.meta?.header;
    }

    /**
     * If the column is missing a meta header, use the accessor key.
     */
    return column.columnDef.accessorKey;
  }, [column]);

  return (
    <DropdownMenuCheckboxItem
      key={column.id}
      className="capitalize text-primary"
      checked={column.getIsVisible()}
      onCheckedChange={(value) => {
        column.toggleVisibility(!!value)
        PostHogAdapter.logEvent("table_column_toggled", {name: column.id, visible: !!value})
      }}
    >
      {title}
    </DropdownMenuCheckboxItem>
  );
};
