import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@elton/ui/core";
import { Download, File, FileSpreadsheet } from "@elton/ui/icons";

import { T } from "@transifex/react";

interface IProps {
  onExport: (format: "csv" | "xlsx") => void;
  disabled?: boolean;
}

export const ExportDropdown: React.FC<IProps> = ({ onExport, disabled }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="flex gap-2" disabled={disabled}>
          <span>
            <T _str="Export" />
          </span>
          <Download className="w-4 h-auto" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>
          <T _str="Choose format" />
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={() => onExport("csv")} asChild>
            <Button
              size="sm"
              variant="ghost"
              className="w-full justify-start font-normal hover:outline-none ring-0 hover:ring-0"
              type="button"
            >
              <File className="mr-2 h-4 w-4" />
              <span>CSV</span>
            </Button>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => onExport("xlsx")} asChild>
            <Button
              size="sm"
              variant="ghost"
              className="w-full justify-start font-normal"
              type="button"
            >
              <FileSpreadsheet className="mr-2 h-4 w-4" />
              <span>XLSX</span>
            </Button>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
