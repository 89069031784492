import { CellContext, ColumnDef } from "@tanstack/react-table";

import { ChargeSession } from "@elton/types";
import { localFormatDate } from "@elton/utils";

import { SortableColumn } from "../components/table-columns";

import { useT } from "@transifex/react";

type ChargeSessionColumnDef = ColumnDef<ChargeSession>[];
type ChargeSessionCellContext = CellContext<ChargeSession, unknown>;

export const useSessionColumns = (): ChargeSessionColumnDef => {
  const t = useT();

  return [
    {
      accessorKey: "id",
      header: SortableColumn("ID"),
      meta: {
        header: t("ID"),
      },
    },
    {
      accessorKey: "identifier",
      header: SortableColumn("Phone"),
      size: 10,
      meta: {
        header: t("Phone"),
      },
    },
    {
      accessorKey: "user",
      header: SortableColumn("User"),
      size: 10,
      meta: {
        header: t("User"),
      },
    },
    {
      accessorKey: "kwh",
      filterFn: "includesString",
      header: SortableColumn(t("kWh")),
      meta: {
        header: t("kWh"),
      },
      size: 10,
      cell: ({ row }: ChargeSessionCellContext) => {
        return (
          <span>
            {row.original.kwh} {t("kWh")}
          </span>
        );
      },
    },
    {
      accessorKey: "price",
      filterFn: "includesString",
      header: SortableColumn(t("Price")),
      meta: {
        header: t("Price"),
      },
      size: 10,
      cell: ({ row }: ChargeSessionCellContext) => {
        return (
          <span>
            {(row.original.price.amount / 100.0).toFixed(2)}{" "}
            {row.original.price.currency}
          </span>
        );
      },
    },
    {
      accessorKey: "departmentName",
      header: SortableColumn(t("Department")),
      meta: {
        header: t("Department"),
      },
    },
    {
      accessorKey: "station",
      filterFn: "includesString",
      header: SortableColumn(t("Station")),
      meta: {
        header: t("Station"),
      },
    },
    {
      accessorKey: "registrationNumberNote",
      header: t("Reg. num"),
      meta: {
        header: t("Reg. num"),
      },
    },
    {
      accessorKey: "note",
      header: t("Note"),
      meta: {
        header: t("Note"),
      },
    },
    {
      accessorKey: "reference",
      filterFn: "includesString",
      header: SortableColumn(t("External Reference")),
      meta: {
        header: t("External Reference"),
      },
    },
    {
      accessorKey: "timestamp",
      size: 100,
      header: SortableColumn(t("Charged At"), "flex w-fit ml-auto"),
      meta: {
        header: t("Charged At"),
      },
      cell: ({ row }: ChargeSessionCellContext) => {
        return (
          <span className="flex justify-end text-right">
            {localFormatDate(row.original.timestamp, "PRETTY_COMPACT")}
          </span>
        );
      },
    },
  ];
};
