import { Link } from "@tanstack/react-router";
import { CellContext, ColumnDef } from "@tanstack/react-table";

import { ListCompany } from "@elton/types";

import { CompanyDropdownActions } from "../actions/company-actions";
import {
  AlignRightColumn,
  MediumColumn,
  SortableColumn,
} from "../components/table-columns";

import { useT } from "@transifex/react";

type CompanyColumnDef = ColumnDef<ListCompany>[];
type CompanyCellContext = CellContext<ListCompany, unknown>;

interface IColumnProps {
  onDelete: (companyId: string) => void;
  onCopyId: (companyId: string) => void;
}

export const useCompanyColumns = ({
  onDelete,
  onCopyId,
}: IColumnProps): CompanyColumnDef => {
  const t = useT();
  return [
    {
      accessorKey: "id",
      header: SortableColumn("ID"),
      meta: {
        header: t("ID"),
      },
    },
    {
      accessorKey: "name",
      header: SortableColumn(t("Name")),
      cell: ({ row }: CompanyCellContext) => (
        <Link
          to="/companies/$companyId"
          params={{ companyId: row.original.id! }}
          className="hover:underline"
        >
          <MediumColumn title={row.original.name} />
        </Link>
      ),
      meta: {
        header: t("Name"),
      },
    },
    {
      accessorKey: "organisationNumber",
      header: t("Org nr."),
      meta: {
        header: t("Org nr."),
      },
    },
    {
      id: "actions",
      enableHiding: false,
      size: 100,
      header: () => <AlignRightColumn title={t("Actions")} />,
      cell: ({ row }: CompanyCellContext) => {
        return (
          <CompanyDropdownActions
            companyId={row.original.id!}
            onDelete={onDelete}
            onCopyId={onCopyId}
            className="flex justify-end text-right"
          />
        );
      },
    },
  ];
};
