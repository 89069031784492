import posthog from 'posthog-js'

import {Company, PaymentPlan} from "@elton/types";

import {kindeClient} from "@/api/clients/kinde-client.ts";

let hasInit = false
let hasLoggedCompany = false

const init = () => {
  posthog.init(import.meta.env.VITE_POSTHOG_ID, {
    api_host: 'https://eu.i.posthog.com',
    person_profiles: 'identified_only'
  })
};

const identify = (companyData: Company) => {
  if (hasInit) return

  const user = kindeClient.getUser();

  if (!user || !user.id) {
    console.warn("Posthog not loaded")
    return;
  }

  const {
    city,
    country,
    customerNumber,
    id,
    name,
    organizationNumber,
  } = companyData

  posthog.identify(
    user.id,
    {
      email: user.email,
      companyCity: city,
      companyCountry: country,
      companyId: id,
      company: name,
      customerNumber,
      organizationNumber,
    },
  );

  hasInit = true
}


const cleanUpIdRegex = /\/companies\/[a-f0-9-]{36}\//;
const logPageView = (location: Location) => {
  try {
    // remove the company id from the url, for better stats
    const url = window.origin + location.href?.replace(cleanUpIdRegex, '/companies/');
    posthog.capture('$pageview', {"$current_url": url});
  }
  catch (e) {
    console.error("posthog pageview error", e)
  }
}


const logCompanyPlan = (data: PaymentPlan) => {
  try {
    if(hasLoggedCompany) return
    const {id} = kindeClient.getUser();
    if (!id) {
      return;
    }
    posthog.identify(id, {
      companyPlan: data.name,
      companyPlanUserLimit: data.userLimit,
      companyPlanCurrency: data.monthlyCostCurrency,
    })
    hasLoggedCompany = true
  }
  catch (e) {
    console.error("posthog company plan error", e)
  }
}

const logEvent = (event: string, data: Record<string, unknown>) => {
    try {
        posthog.capture(event, data)
    }
    catch (e) {
        console.error("posthog event error", e)
    }
}

type Location = {
  pathname: string;
  search: Record<string, unknown>;
  searchStr: string;
  hash: string;
  href: string;
};


const PostHogAdapter = {
  init,
  identify,
  logPageView,
  logCompanyPlan,
  logEvent,
};

export default PostHogAdapter;
