import { useMemo } from "react";

import { formatCentsToDollars } from "@elton/utils";

interface IProps {
  monthlyCost?: number;
  currency?: string;
}

export const useMonthlyCostConverter = ({ monthlyCost, currency }: IProps) => {
  return useMemo(() => {
    if (monthlyCost && currency) {
      return `${formatCentsToDollars(monthlyCost)} ${currency}`;
    }

    return "-";
  }, [monthlyCost, currency]);
};
