import { useEffect } from "react";

import { CreateExport, createExportSchema } from "@elton/types";
import {
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@elton/ui/core";
import { cn } from "@elton/ui/lib/utils";

import { ExportDropdown } from "@/components/dropdowns/export-dropdown/export-dropdown";
import { DateField } from "@/components/fields/date-field";

import DateRangePresetList from "./date-range-preset-list";
import { useDateRangePresets } from "./use-date-range-presets";
import { useExportForm } from "./use-export-form";

import { useT } from "@transifex/react";

interface IProps {
  data: CreateExport;
  loading?: boolean;
  onCreateExport: (exp: CreateExport, format: "csv" | "xlsx") => void;
  onChangeDate: (exp: CreateExport) => void;
}

export const ExportForm: React.FC<IProps> = ({
  data,
  onCreateExport,
  onChangeDate,
}) => {
  const t = useT();

  const dateRangePresets = useDateRangePresets();

  const form = useExportForm({
    data: {},
    onSubmit: () => {}, // Handle submits manually
  });

  const handleDateRangeChange = (dateRange: any) => {
    form.setFieldValue("dateFrom", dateRange.from);
    form.setFieldValue("dateTo", dateRange.to);

    onChangeDate({
      dateFrom: dateRange.from,
      dateTo: dateRange.to,
    });
  };

  useEffect(() => {
    form.setFieldValue("dateFrom", data.dateFrom);
    form.setFieldValue("dateTo", data.dateTo);
  }, []);

  return (
    <form.Subscribe
      children={(state) => (
        <>
          <Popover>
            <form
              className={cn("flex items-end gap-2 text-primary")}
              tabIndex={-1}
            >
              <PopoverTrigger
                className="flex flex-row w-full gap-2"
                tabIndex={-1}
              >
                <form.Field
                  name="dateFrom"
                  validators={{
                    onChange: createExportSchema.shape.dateFrom,
                  }}
                  children={(field) => (
                    <>
                      <DateField
                        className="xl:w-[200px]"
                        tabIndex={0}
                        field={field}
                        label=""
                        placeholder={t("Start date")}
                        required={true}
                        onClickReset={(e) => {
                          e.preventDefault();
                          handleDateRangeChange({
                            from: undefined,
                            to: state.values.dateTo,
                          });
                        }}
                      />
                    </>
                  )}
                />
                <form.Field
                  name="dateTo"
                  validators={{
                    onChange: createExportSchema.shape.dateFrom,
                  }}
                  children={(field) => (
                    <>
                      <DateField
                        tabIndex={0}
                        className="mb-0 xl:w-[200px]"
                        field={field}
                        label=""
                        placeholder={t("End date")}
                        required={true}
                        onClickReset={(e) => {
                          e.preventDefault();
                          handleDateRangeChange({
                            from: state.values.dateFrom,
                            to: undefined,
                          });
                        }}
                      />
                    </>
                  )}
                />
              </PopoverTrigger>
              <ExportDropdown
                disabled={!(state.values.dateFrom && state.values.dateTo)}
                onExport={(format) => {
                  onCreateExport(state.values, format);
                }}
              />
            </form>
            <PopoverContent className="w-auto p-0 flex" align="start">
              <Calendar
                initialFocus
                mode="range"
                defaultMonth={
                  state.values.dateFrom
                    ? new Date(state.values.dateFrom)
                    : new Date()
                }
                selected={{
                  from: state.values.dateFrom
                    ? new Date(state.values.dateFrom)
                    : undefined,
                  to: state.values.dateTo
                    ? new Date(state.values.dateTo)
                    : undefined,
                }}
                onSelect={handleDateRangeChange}
                numberOfMonths={2}
              />
              <div className="border p-4 w-48">
                <DateRangePresetList
                  presets={dateRangePresets}
                  onSelectPreset={(preset) => {
                    handleDateRangeChange({
                      from: preset.dateFrom,
                      to: preset.dateTo,
                    });
                  }}
                />
              </div>
            </PopoverContent>
          </Popover>
        </>
      )}
    />
  );
};
