import { createFileRoute, redirect } from "@tanstack/react-router";

import { Company } from "@elton/types";

import { SidebarLayout } from "@/pages/_layouts/sidebar-layout/sidebar-layout";
import { ErrorPage } from "@/pages/error-page/error-page";

import PostHogAdapter from "@/adapters/posthog-adapter.ts";
import { queryClient } from "@/api/clients/query-client";
import { getCompanyOptions } from "@/api/options/company-options";
import { getCompanies } from "@/api/requests/companies/get-companies";

export const Route = createFileRoute(
  "/_authenticated/companies/$companyId/_dashboard"
)({
  loader: async ({ params }) => {
    return {
      company: await requireCompany(params.companyId),
      companies: (await getCompanies()).data,
    };
  },
  component: SidebarLayout,
  errorComponent: ErrorPage,
});

type TRequireCompanyResult = Promise<Company>;

const requireCompany = async (companyId: string): TRequireCompanyResult => {
  const queryOptions = getCompanyOptions(companyId);
  const cachedData = queryClient.getQueryData<Company>(queryOptions.queryKey);

  if (cachedData) {
    return cachedData;
  }

  const companyData = await queryClient.fetchQuery(queryOptions);

  if (!companyData) {
    throw redirect({
      to: "/companies",
      search: { filter: "", limit: 10, offset: 0, sort: "" },
    });
  }

  PostHogAdapter.identify(companyData);
  return companyData;
};
