import { useState } from "react";
import { VisibilityState } from "@tanstack/react-table";

const DEFAULT_VISIBILITY_STATE: VisibilityState = {
  id: false,
};

export const useColumnVisibility = (state?: VisibilityState) => {
  return useState<VisibilityState>(state ?? DEFAULT_VISIBILITY_STATE);
};
