import { CreateEmployee } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client";

export const createEmployee = async (
  companyId: string,
  data: CreateEmployee
) => {
  const res = await getServicesClient().post(`/companies/${companyId}/users`, {
    name: data.name,
    phoneNumber: data.phoneNumber,
    departmentId: data.departmentId,
    registrationNumber:
      data.registrationNumber === "" ? undefined : data.registrationNumber,
    defaultNote: data.defaultNote === "" ? undefined : data.defaultNote,
    // registrationNumber: data.registrationNumber,
    // defaultNote: data.defaultNote,
  });

  return res.data;
};
