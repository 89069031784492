import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";

import { CreateExport } from "@elton/types";

interface IProps {
  data: CreateExport;
  onSubmit: (value: CreateExport) => void;
}

export const useExportForm = ({ data, onSubmit }: IProps) => {
  const form = useForm({
    defaultValues: data,
    validatorAdapter: zodValidator(),
    onSubmit: (values) => {
      return onSubmit(values.value);
    },
  });

  // useEffect(() => {
  //   if (form.state.values.dateFrom !== data?.dateFrom) {
  //     form.setFieldValue("dateFrom", data.dateFrom);
  //   }
  //   if (form.state.values.dateTo !== data.dateTo) {
  //     form.setFieldValue("dateTo", data.dateTo);
  //   }
  // }, [data, form]);

  return form;
};
