import { z } from "zod";

export enum ExportFormat {
  XLSX = "xlsx",
  CSV = "csv",
}

export const createMasterExportSchema = z.object({
  monthNumber: z.number().min(1).max(12),
  year: z.number(),
  format: z.nativeEnum(ExportFormat),
});

export type CreateMasterExport = z.infer<typeof createMasterExportSchema>;
