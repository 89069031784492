import { Key } from "react";
import { FieldApi } from "@tanstack/react-form";

import {
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@elton/ui/core";
import { Asterisk } from "@elton/ui/icons";
import { cn } from "@elton/ui/lib/utils";

import { FieldInfo } from "./field-info/field-info";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TextFieldProps = FieldApi<any, any, any, any, any>;

interface ISelectProps<TData> {
  keyExtractor: { key: keyof TData; value: keyof TData };
  renderItem?: (_: TData) => React.ReactNode;
  field: TextFieldProps;
  placeholder?: string;
  label: string;
  data: TData[];
  className?: string;
  required?: boolean;
  disabled?: boolean;
}

export function SelectField<TData>({
  field,
  label,
  data,
  placeholder = "",
  keyExtractor: extractor,
  renderItem = (value: TData) => value[extractor.value] as string,
  className,
  required,
  disabled,
}: ISelectProps<TData>) {
  return (
    <div className={cn("space-y-2", className)}>
      <Label>{label}</Label>
      <Select
        onValueChange={(value) => {
          field.handleChange(value);
        }}
        value={field.state.value}
      >
        <SelectTrigger
          disabled={disabled}
          className={
            required && !!field.state.meta.errors.length
              ? "focus:border-input border-red-500"
              : ""
          }
        >
          <div className="flex items-center flex-1 justify-between">
            <SelectValue placeholder={placeholder} />
            {required && !field.state.value && (
              <Asterisk className="w-3 mr-2" />
            )}
          </div>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {data?.map((value) => {
              return (
                <SelectItem
                  key={value[extractor.key] as Key}
                  value={value[extractor.key] as string}
                >
                  {renderItem(value)}
                </SelectItem>
              );
            })}
          </SelectGroup>
        </SelectContent>
      </Select>
      <FieldInfo field={field} />
    </div>
  );
}
