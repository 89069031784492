import { forwardRef } from "react";
import { FieldApi } from "@tanstack/react-form";

import { Button, Label } from "@elton/ui/core";
import { Input } from "@elton/ui/core";
import { CalendarIcon, XIcon } from "@elton/ui/icons";
import { cn } from "@elton/ui/lib/utils";
import { localFormatDate } from "@elton/utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DateFieldProps = FieldApi<any, any, any, any, any>;
type Ref = HTMLInputElement;

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  field: DateFieldProps;
  label: string;
  className?: string;
  onClickReset?: React.MouseEventHandler<HTMLButtonElement>;
  renderValue?: (value: Date) => string;
}

export const DateField = forwardRef<Ref, InputProps>(
  (
    {
      field,
      label,
      className,
      renderValue,
      onClickReset = (e) => {
        e.preventDefault();
      },
      ...rest
    },
    ref,
  ) => {
    return (
      <div className={cn("space-y-2 text-left", className)}>
        <Label>{label}</Label>
        <div className="relative">
          <div className="absolute inset-y-0 w-12 flex items-center justify-around">
            <CalendarIcon className="h-4 w-4 opacity-50" />
          </div>
          <Input
            ref={ref}
            variant={field.state.meta.errors.length ? "destructive" : "default"}
            id={field.name}
            name={field.name}
            value={
              renderValue
                ? renderValue(field.state.value)
                : field.state.value
                  ? localFormatDate(field.state.value, "PRETTY_RANGE")
                  : ""
            }
            onBlur={field.handleBlur}
            onChange={() => {
              /**
               * Currently this component is only used as a part of a date range picker.
               * Meaning there is no real need to manipulate the input field directly, the user
               * should pick dates from the calendar instead.
               */
              //field.handleChange(e.target.value)
            }}
            className="pl-10 pr-10"
            {...rest}
          />
          <Button
            className={cn(
              "absolute right-0 top-0",
              !field.state.value && "hidden",
            )}
            variant="ghost"
            onClick={onClickReset}
            asChild
          >
            <div>
              <XIcon className="w-4 h-4 text-muted-foreground" />
            </div>
          </Button>
        </div>
      </div>
    );
  },
);
