import { Account, accountSchema, UpdateAccount } from "@elton/types";
import {
  SheetClose,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@elton/ui/core";

import { LoadingButton } from "@/components/buttons/loading-button";
import { InputField } from "@/components/fields/input-field";

import { TAccountFormData, useAccountForm } from "./use-account-form";

import { T, useT } from "@transifex/react";

interface IProps {
  data: Account;
  onUpdate: (data: UpdateAccount) => void;
  onCreate: (data: Account) => void;
  loading?: boolean;
}

export const AccountForm: React.FC<IProps> = ({
  data,
  onUpdate,
  onCreate,
  loading,
}) => {
  const t = useT();
  const form = useAccountForm({
    data,
    onSubmit: (data: TAccountFormData) => {
      if (data.action === "create") {
        onCreate(data.account);
      } else {
        onUpdate(data.account);
      }
    },
  });

  const handleFormSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    void form.handleSubmit();
  };

  return (
    <form.Subscribe
      selector={(state) => [state.values.id === "" ? "create" : "update"]}
      children={([action]) => (
        <>
          <SheetHeader>
            <SheetTitle>
              {action === "create" ? t("Create admin") : t("Update admin")}
            </SheetTitle>
            <SheetDescription>
              <T _str="Create a new admin - Click the button when you're done!" />
            </SheetDescription>
          </SheetHeader>
          <form className="space-y-4 text-primary">
            <form.Field
              name="firstName"
              validators={{
                onChange: accountSchema.shape.firstName,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  label={t("First name")}
                  placeholder={t("First name")}
                  required={true}
                />
              )}
            />
            <form.Field
              name="lastName"
              validators={{
                onChange: accountSchema.shape.lastName,
              }}
              children={(field) => (
                <InputField
                  field={field}
                  label={t("Last name")}
                  placeholder={t("Last name")}
                  required={true}
                />
              )}
            />

            {action === "create" && (
              <form.Field
                name="email"
                validators={{
                  onChange: accountSchema.shape.email,
                }}
                children={(field) => (
                  <InputField
                    type="email"
                    field={field}
                    label={t("Email")}
                    placeholder="email@example.com"
                    required={true}
                  />
                )}
              />
            )}
            <SheetFooter>
              <SheetClose asChild>
                <LoadingButton
                  loading={loading}
                  onClick={handleFormSubmit}
                  id="create-account"
                  type="submit"
                >
                  {action === "create" ? t("Create") : t("Update")}
                </LoadingButton>
              </SheetClose>
            </SheetFooter>
          </form>
        </>
      )}
    />
  );
};
