import { forwardRef } from "react";

import { Button } from "@elton/ui/core";
import { ChevronDown } from "@elton/ui/icons";
import { cn } from "@elton/ui/lib/utils";

type Ref = HTMLButtonElement;
type Props = React.ComponentPropsWithoutRef<"button">;

export const DropdownButton = forwardRef<Ref, Props>(
  ({ children, className, ...rest }, ref) => {
    return (
      <Button
        id="dropdown-button"
        {...rest}
        className={cn("gap-1", className)}
        ref={ref}
        variant="outline"
      >
        {children}
        <ChevronDown className="w-3 h-auto stroke-primary" />
      </Button>
    );
  }
);
