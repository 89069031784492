import { UpdateAccount } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client";

export const updateAccount = async (data: UpdateAccount) => {
  const res = await getServicesClient().patch(`/accounts/${data.id}`, {
    firstName: data.firstName,
    lastName: data.lastName,
  });

  return res.data;
};
