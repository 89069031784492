import { useEffect } from "react";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";

import { Account, UpdateAccount } from "@elton/types";

interface IProps {
  data: Account;
  onSubmit: (data: TAccountFormData) => void;
}

export type TAccountFormData =
  | { action: "create"; account: Account }
  | { action: "update"; account: UpdateAccount };

export const useAccountForm = ({ data, onSubmit }: IProps) => {
  const form = useForm({
    defaultValues: data,
    validatorAdapter: zodValidator(),
    onSubmit: (values) => {
      const { email, ...updateAccount } = values.value;
      return onSubmit(
        data.id
          ? { action: "update", account: updateAccount }
          : { action: "create", account: { email, ...updateAccount } },
      );
    },
  });

  useEffect(() => {
    form.setFieldValue("id", data.id);
    form.setFieldValue("firstName", data.firstName);
    form.setFieldValue("lastName", data.lastName);
    form.setFieldValue("email", data.email);
  }, [data, form]);

  return form;
};
