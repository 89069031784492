import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@elton/ui/core";

import { ZapIcon } from "@ui/components/icons";
import { cn } from "@ui/lib/utils";

import { HomeLink } from "@/components/links/home-link/home-link";

import { T } from "@transifex/react";

export const LoginHeader: React.FC = () => {
  return (
    <header className="flex items-center justify-between h-16 px-2 md:px-6">
      <HomeLink />
      <div>
        <NavigationMenu>
          <NavigationMenuList>
            <NavigationMenuItem className="cursor-pointer text-[#00AB98]">
              <a
                className={cn(
                  "inline-flex gap-2",
                  navigationMenuTriggerStyle(),
                )}
                href="https://elton.app/business/elton-business"
                target="_blank"
                referrerPolicy="no-referrer"
              >
                <ZapIcon className="w-4 h-auto" />
                <span>
                  <T _str="Request Access" />
                </span>
              </a>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </div>
    </header>
  );
};
