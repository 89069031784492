import { createFileRoute } from "@tanstack/react-router";

import { ExportsPage } from "@/pages/exports-page/exports-page";

import { getDateRangeLoaderDeps } from "@/api/params/date-range-params";

export const Route = createFileRoute("/_authenticated/_restricted/exports")({
  component: ExportsPage,
  loaderDeps: getDateRangeLoaderDeps,
});
