import { CellContext, ColumnDef } from "@tanstack/react-table";

import { CreateDepartment, ListDepartment } from "@elton/types";

import { DepartmentDropdownActions } from "../actions/department-actions";
import {
  AlignRightColumn,
  MediumColumn,
  SortableColumn,
} from "../components/table-columns";

import { useT } from "@transifex/react";

type DepartmentColumnDef = ColumnDef<ListDepartment>[];
type DepartmentCellContext = CellContext<ListDepartment, unknown>;

interface IColumnProps {
  onUpdate: (value: CreateDepartment) => void;
  onDelete: (value: CreateDepartment) => void;
  onCopyId: (value: string) => void;
}

export const useDepartmentColumns = ({
  onUpdate,
  onDelete,
  onCopyId,
}: IColumnProps): DepartmentColumnDef => {
  const t = useT();

  return [
    {
      accessorKey: "id",
      header: SortableColumn("ID"),
      meta: {
        header: t("ID"),
      },
    },
    {
      accessorKey: "name",
      filterFn: "includesString",
      header: SortableColumn(t("Name")),
      meta: {
        header: t("Name"),
      },
      cell: ({ row }: DepartmentCellContext) => (
        <MediumColumn title={row.original.name} />
      ),
    },
    {
      accessorKey: "userCount",
      header: t("Employees"),
      size: 10,
      meta: {
        header: t("Employees"),
      },
    },
    {
      id: "actions",
      enableHiding: false,
      size: 100,
      header: () => <AlignRightColumn title={t("Actions")} />,
      cell: ({ row }: DepartmentCellContext) => {
        return (
          <DepartmentDropdownActions
            data={row.original}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onCopyId={onCopyId}
            className="flex justify-end text-right"
          />
        );
      },
    },
  ];
};
