import { UseMutationOptions } from "@tanstack/react-query";

import { CreateEmployee, Employee } from "@elton/types";

import { createEmployee } from "../requests/employees/create-employee";
import { deleteEmployee } from "../requests/employees/delete-employee";
import { updateEmployee } from "../requests/employees/update-employee";

export const createEmployeeOptions = (
  companyId: string
): UseMutationOptions<CreateEmployee, Error, CreateEmployee> => ({
  mutationFn: async (data: CreateEmployee) => createEmployee(companyId, data),
});

export const updateEmployeeOptions = (): UseMutationOptions<
  CreateEmployee,
  Error,
  CreateEmployee
> => ({
  mutationFn: async (data: CreateEmployee) => updateEmployee(data),
});

export const deleteEmployeeOptions = (): UseMutationOptions<
  Employee,
  Error,
  string
> => ({
  mutationFn: async (userId: string) => deleteEmployee(userId),
});
